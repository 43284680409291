import * as React from "react";
import { Box, Typography, Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import bestch from "../assets/images/best.png";

let navLinks = [
  {
    to: "/reserves",
    name: "Резервы",
  },
  {
    to: "/courses",
    name: "Курсы",
  },
  {
    to: "/agreement",
    name: "Соглашение",
  },
  {
    to: "/contacts",
    name: "Контакты",
  },
];

export default function AppFooter() {
  return (
    <Box
      sx={{
        p: 1,
        bgcolor: "#3bd2a287",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Typography>
            © {new Date().getFullYear()}, Menyatt.com - Безопасный и быстрый
            обмен криптовалют
          </Typography>
          <Box>
            {navLinks.map((l) => {
              return (
                <Typography
                  sx={{ mr: 2, color: "#475259c4" }}
                  component={NavLink}
                  to={l.to}
                  variant="body1"
                >
                  {l.name}
                </Typography>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ mt: 1, display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
          <div>
            <a
              id="wot-badge0"
              class="wot-badge"
              href="https://www.mywot.com/scorecard/menyatt.com?wot_badge=0_white"
              target="_blank"
            >
              <div class="wot-logo"></div>
              <div class="wot-shield"></div>
              <p class="wot-secured">Сайт верифицирован</p>
              <div class="wot-vertical"></div>
              <p class="wot-report">Отзывы</p>
            </a>
          </div>
          <Box
            component="a"
            target="_blank"
            sx={{
              display: "block",
              height: 'min-content',
            }}
            href="https://www.okchanger.ru/exchangers/Menyatt?a=240480"
          >
            <Box component='img' sx={{display: 'block'}} src="https://www.okchanger.ru/images/banners/90x32.png" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
