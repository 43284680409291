import React, { useState, useEffect } from "react";
import { Box, Container, Typography, Grid, Avatar, Card } from "@mui/material";
import getImgUrl from "../../common/getImgUrl";
import ExchangeService from "../../services/Exchange";

const CoursesPage = () => {
  let [courses, setCourses] = useState([]);

  useEffect(() => {
    ExchangeService.getAll().then((res) => {
      setCourses(res.data);
    });
  }, []);

  return (
    <Box>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          textAlign="center"
          sx={{ mb: 5, mx: 1, fontWeight: 400 }}
        >
          Курсы
        </Typography>
        <Card sx={{ p: 3 }}>
          <Grid container spacing={2}>
            {courses.map((course) => (
              <Grid item xs={12} key={course._id} sm={6}>
                <CourseCard course={course} />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

function CourseCard({ course }) {
  let {
    image: takeImg,
    name: takeName,
    sum: takeSum,
    code: takeCode,
  } = course.take;
  let {
    image: giveImg,
    name: giveName,
    sum: giveSum,
    code: giveCode,
  } = course.give;
  return (
    <Box>
      <Currency img={takeImg} name={takeName} sum={takeSum} code={takeCode} />
      <Currency img={giveImg} name={giveName} sum={giveSum} code={giveCode} />
    </Box>
  );
}

function Currency({ img, name, sum, code }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar src={getImgUrl(img)} sx={{ width: 45, height: 45 }} />
      <Box sx={{ ml: 1.5 }}>
        <Typography noWrap sx={{ color: "#8595a6", fontSize: "16px" }}>
          {name}
        </Typography>
        <Typography sx={{ color: "#37373c", fontSize: "18px" }}>
          {sum} {code}
        </Typography>
      </Box>
    </Box>
  );
}

export default CoursesPage;
