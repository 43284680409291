import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import { socket } from "./socket";
import StoreProfile from "../src/store/profile";
import ExchangerPage from "./pages/Exchanger/Exchanger";
import OrdersList from "./components/OrderList/OrderList";
import CardList from "./components/CardList/CardList";
import Settings from "./components/Settings/Settings";

import Order from "./components/Order/Order";
import Card from "./components/Card/Card";
import FAQPage from "./pages/FAQPage/FAQPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import ReservePage from "./pages/ReservePage/ReservePage";
import AgreementPage from "./pages/AgreementPage/AgreementPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import ProfileRouter from "./pages/ProfileRouter/ProfileRouter";
import Referral from "./components/Referral/Referral";
import Cashback from "./components/Cashback/Cashback";
import Withdraw from "./components/Withdraw/Withdraw";
import CoursesPage from "./pages/Courses/index";

const authRoutes = (
  <>
    <Route path="cards/:id" element={<Card />} />
    <Route path="profile" element={<ProfileRouter />}>
      <Route path="orders" element={<OrdersList />} />
      <Route path="settings" element={<Settings />} />
      <Route path="referral" element={<Referral />} />
      <Route path="cashback" element={<Cashback />} />
      <Route path="withdraw" element={<Withdraw />} />
      <Route path="cards" element={<CardList />} />
    </Route>
  </>
);

function Router() {
  let isAuth = StoreProfile.isAuth;

  useEffect(() => {
    if (isAuth) {
      socket.on("update user", (u) => StoreProfile.setProfile(u));
    }
    return () => {
      socket.off("update user");
    };
  }, [isAuth]);

  return (
    <Routes>
      <Route path="/" element={<ExchangerPage />} />
      <Route path="faqs" element={<FAQPage />} />
      {/* <Route path="news" element={<NewsPage />} /> */}
      <Route path="contacts" element={<ContactsPage />} />
      <Route path="reserves" element={<ReservePage />} />
      <Route path="courses" element={<CoursesPage />} />
      <Route path="agreement" element={<AgreementPage />} />
      {isAuth ? authRoutes : null}
      <Route path="orders/:id" element={<Order />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default observer(Router);
